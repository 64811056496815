body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-slide img {
  width: 100%;
  border-radius: 10px;
}

.slick-thumb li {
  width: 60px !important;
  height: 45px !important;
}

.slick-dots {
  transform: translate(0px, 69%);
  bottom: -40px;
  height: 100px;
  overflow: auto;
}

.slick-dots .slick-active {
  /* border: 1px solid #1976d2; */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  border-radius: 4px;
}

.slick-dots li img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
}

.MuiSvgIcon-root {
  cursor: pointer;
}

.list-image {
  width: 200px;
  height: 150px;
  object-fit: cover;
}

.cars-images {
  margin: auto;
}

.silder-modal .slick-next:before,
.silder-modal .slick-prev:before {
  color: black;
}

.error {
  color: red;
}

.slider-image {
  width: 760px;
  margin: 30px auto;
}
.add-images{
  height: 164px !important;
}
.active-image{
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.4), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.slick-next:before,
.slick-prev:before {
  display: none !important;
}

.slick-next:hover,
.slick-prev:hover {
  color: black;
}
.slick-slide .add-car-img{
  height: 260px;
  object-fit: cover;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loading-indicator:before {
  content: "";
  background: #0000008f;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999999999999999;
}

.loading-indicator:after {
  content: "";
  animation: spin 1s ease-in-out infinite;
  position: fixed;
  width: 100%;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  z-index: 99999999999;
}
.add-employee-img{
  width: 500px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}
.responsive-form {
  margin-top: 110px;
}
@media only screen and (max-width: 1450px) {
  .responsive-form {
    float: left !important;
  }
}